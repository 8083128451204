<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.displayName">

    <div class="text-right leading-tight">
      <p class="font-semibold">{{ activeUserInfo.displayName }}</p>
      <small>Online</small>
    </div>

    <vs-dropdown vs-custom-content class="cursor-pointer">

      <div class="con-img ml-3">
        <vs-avatar :src="activeUserInfo.photoURL" size="40px"/>
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="redirectToProfile()">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/calendar').catch(() => {})">
            <feather-icon icon="CalendarIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Calendar</span>
          </li>
          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/my-listings').catch(() => {})">
            <feather-icon icon="MenuIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">My listing</span>
          </li>
          <vs-divider class="m-1" />
       <!--    <li
          v-if="this.activeUserInfo.isPartner === false"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/user/marketplace').catch(() => {})">
            <feather-icon icon="ShoppingCartIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">My listing</span>
          </li>  -->
           <li
          v-if="this.activeUserInfo.isPartner === true"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="goToJobPortal">
            <feather-icon icon="BriefcaseIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Job listing</span>
          </li>
         <vs-divider v-if="this.activeUserInfo.isPartner === true"  class="m-1" />  
           <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/user/settings').catch(() => {})">
            <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Settings</span>
          </li>
      
          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  computed: {
    activeUserInfo () {
      return this.$store.state.auth.userData
    }
  }, 
  methods: { 
    logout () {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
    goToJobPortal () {
      this.$router.push(`/${this.activeUserInfo.name}/job-portal`).catch(() => {})
    },
    redirectToProfile () {
      //it need to be user name
      if (this.activeUserInfo.isPartner) {
        this.$router.push(`/partner/${this.activeUserInfo.name}`)
      } else {
        this.$router.push(`/profile/${this.activeUserInfo.name}`)
      }
    }
  }
}
</script>
