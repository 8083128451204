<template>
    <div>
        <!-- Bottom Nav Bar -->
        <div class="fixed-bottom bottom-nav d-block d-md-block d-ip-block d-lg-block d-xl-none" :class="{ 'navbar--hidden': !showNavbar }">
            <div class="btn-group selectors" role="group" aria-label="Basic example">
             <b-button type="flat" to="/connect" 
             @click="handleClick('home')"
             :active="currentPage === 'home'">
              <div class="selector-holder">
                <i class="material-icons">home</i>
                <span>Home</span>
              </div>
            </b-button>

      <!--      <b-button type="flat" to="/course-lists" 
            @click="handleClick('courses')"
            :active="currentPage === 'courses' ">
               <div class="selector-holder">
                  <i class="material-icons">menu_book</i>
                  <span>Courses</span>
               </div>
            </b-button> -->

             <!-- <b-button type="flat" to="/sessions" 
            @click="handleClick('sessions')"
            :active="currentPage === 'sessions' ">
               <div class="selector-holder">
                  <i class="material-icons">event</i>
                  <span>Sessions</span>
               </div>
            </b-button>  -->
       

            <!-- <b-button class="" type="flat" to="/coursefeeds" 
            @click="handleClick('coursefeeds')"
            :active="currentPage === 'coursefeeds' ">
               <div class="selector-holder">
                  <i class="material-icons">group</i>
                  <span>Groups</span>
               </div>
            </b-button> -->
            <!-- <b-button type="flat" @click="handleClick('createPost')" href="javascript:void(0);" :active="currentPage === 'createPost' ">
               <div class="selector-holder">
                  <i class="material-icons">create</i>
                  <span>Post</span>
               </div>
            </b-button> -->
          <!--   <b-button class="vtm-step-4" type="flat" @click="handleClick('job-portal')" to="/job-portal" :active="currentPage === 'job-portal' ">
               <div class="selector-holder">
                  <i class="material-icons">work</i>
                  <span>Jobs</span>
               </div>
            </b-button> -->
              <!-- <b-button class="" type="flat" @click="handleClick('my-pals')" to="/my-pals" :active="currentPage === 'my-pals' ">
               <div class="selector-holder">
                  <i class="material-icons">person_add</i>
                  <span>My Pals</span>
               </div>
            </b-button> -->
           <b-button type="flat" @click="handleClick('chat')" to="/chat" :active="currentPage === 'chat' ">
               <div class="selector-holder">
                  <i class="material-icons">chat_bubble</i>
                  <span>Chat</span>
               </div>
           </b-button>
         </div>
      </div>

    <!-- CreatePost popup -->

   
    <!--End CreatePost popup -->

</div>
</template>

<script>
import CreatePost from './CreatePost'

export default {
  data () {
    return {
      currentPage: 'home',
      showNavbar: true,
      lastScrollPosition: 0
    }
  },
  computed: {
    activeUserData () {
      return this.$store.state.auth.userData
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 54) {
        return
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    },
    handleClick (pageName) {
      this.currentPage = pageName
      if (this.currentPage === 'createPost') {
        this.$emit('createPost')
      }
    }
  },
  components: {
    CreatePost
  }
}
</script>

<style scoped lang="scss" >
body {
  background-color: #fff !important;
}
.bottom-nav {
  position:fixed;
  bottom: 0;
  width: 100%;
  //  height: 60px;
  height: 54px;
  background-color: #fff !important;
  border-top: 1px solid transparent;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

  transform: translate3d(0, 0, 0);
  transition: 0.1s all ease-out;
}
.bottom-nav.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, 100%, 0);
}

.group-container{
  padding:0;
  height:100%;
}

.selectors, .block{
  height:100%;
  width:100%;
}

.selectors a{
  border: 0;
  border-radius: 0;
  background-color: #fff !important;
  width:25%;
  margin-left: 0;
}

.selectors a:active{
  border:0;
}
.selectors a:focus{
  border:0;
  outline: 0;
  box-shadow: 0 0 0 0px;
}

.active, .selector-holder{
  display: flex;
  flex-direction: column;
}

.inactive{
  display: none;
}

.selector-holder span{
  font-size: 0.8rem;
}
.bottom-nav a{
  color: #6c757d;
}
// /* Colors of the buttons*/
.active{
  /* color: #f91942 !important;*/
}
.router-link-active{
  color: #f91942 !important;

}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #6c757d;
}
.selectors a:hover{
  background-color: #eee !important;
}
.selectors button:active, .selectors button:focus{
  color: #f91942 !important;
}
/*.navbar {
  height: 60px;
  width: 100vw;
  background: hsl(200, 50%, 50%);
  position: fixed;
  box-shadow: 0 2px 15px rgba(71, 120, 120, 0.5);
  transform: translate3d(0, 0, 0);
  transition: 0.1s all ease-out;
  bottom: 0;
}

.navbar.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, 100%, 0);
}*/
</style>