<template>
  <div v-if="shown"  class="hidden">
   Install our new MyPals mobile app now.

    <!-- <button @click="installPWA">
      Install!
    </button> -->
    <vs-button @click="installPWA" class="" color="success" size="small" type="border">Install</vs-button>

    <!-- <button @click="dismissPrompt">
      No, thanks
    </button> -->
    <vs-button @click ='dismissPrompt' class="ml-1" size="small" color="danger" type="border">No,thanks</vs-button>
  </div>
</template>

<script>
export default {
  data: () => ({
    shown: false,
  }),

  // beforeMount() {
  //   window.addEventListener('beforeinstallprompt', (e) => {
  //     e.preventDefault()
  //     this.installEvent = e
  //     this.shown = true
  //   })
  // },
 
  mounted(){
setTimeout(this.shown = true, 5000)
  },

  methods: {
    dismissPrompt() {
      this.shown = false
    },

    installPWA() {
      // this.installEvent.prompt()
      // this.installEvent.userChoice.then((choice) => {
      //   this.dismissPrompt() // Hide the prompt once the user's clicked
      //   if (choice.outcome === 'accepted') {
      //     // Do something additional if the user chose to install
      //   } else {
      //     // Do something additional if the user declined
      //   }
      // })
         if((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i))) {
            window.location = 'https://testflight.apple.com/join/AHPRiCiC';
    }
    //Android Version:
    else if(navigator.userAgent.match(/android/i)) {
    
            window.location = "https://play.google.com/store/apps/details?id=app.coursepal.twa";
    }
    //else{
     //      window.location = "https://mypals.app/";
    //}
    },
  }
}
</script>