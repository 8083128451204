<template>
<div class="pdd-v3">
    <!-- <loading-overlay :active="isLoading" :is-full-page="fullPage" :loader="loader" :color="color"></loading-overlay> -->

    <b-nav>
        <b-nav-item-dropdown id="my-nav-dropdown" right>
            <template #button-content>
                <a class="flex items-center" href.prevent>
                    <div class="text-right leading-tight flex items-center pr-0" v-if="activeUserInfo.displayName">
                        <div class="">
                            <p class="font-semibold">{{ activeUserInfo.displayName }}</p>
                            <small class='text-success'>Online</small>
                        </div>
                        <div class="con-img ml-2">
                            <avatar style="margin-left:5px;" v-if="activeUserInfo.photoURL.split('/')[activeUserInfo.photoURL.split('/').length-1] === 'default_dp.png'" class="border-2 border-solid border-white mr-2 my-1" :username="activeUserInfo.displayName" :size="40" />

                            <vs-avatar :src="activeUserInfo.photoURL" size="40px" v-else />
                        </div>
                    </div>
                    <!-- <i class="material-icons"> expand_more </i> -->
                </a>
            </template>
            <b-dropdown-item class="d-flex align-items-center" :to="redirectToProfile()">
                <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                <span class="ml-1">Profile</span>
            </b-dropdown-item>
        

            <b-dropdown-item class="d-flex align-items-center" v-if="this.activeUserInfo.isPartner === true" :to="goToJobPortal">
                <feather-icon icon="BriefcaseIcon" svgClasses="w-4 h-4" />
                <span class="ml-1">Job listing</span>
            </b-dropdown-item>
            <b-dropdown-item class="d-flex align-items-center" :to="'/user/settings'">
                <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
                <span class="ml-1">Settings</span>
            </b-dropdown-item>

            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item class="d-flex align-items-center" @click="logout">
                <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                <span class="ml-1">Logout</span>
            </b-dropdown-item>
        </b-nav-item-dropdown>
    </b-nav>


</div>
</template>

<script>
// import {
//     Magic
// } from 'magic-sdk';
// import {
//     ConnectExtension
// } from '@magic-ext/connect';

import Avatar from 'vue-avatar'
const customNodeOptions = {
    rpcUrl: 'https://polygon-rpc.com', // your ethereum, polygon, or optimism mainnet/testnet rpc URL
    chainId: 137
}

// const magic = new Magic('pk_live_CB213028D7F844A3', {
//     extensions: [new ConnectExtension()],
//     network: customNodeOptions,
// });
// const web3 = new Web3(magic.rpcProvider);
export default {
    data() {
        return {
            popupMatic: false,
            isLoading: false,
            fullPage: false,
            loader: 'dots',
            color: '#dc123e',
            walletAccounts: ''
        }
    },
    components: {
        Avatar
    },
    computed: {
        activeUserInfo() {
            return this.$store.state.auth.userData
        }
    },
    mounted() {
        // let recaptchaScript = document.createElement('script')
        // recaptchaScript.setAttribute('src', 'https://widget.coinbase.com/embed-v1.3.js')
        // document.head.appendChild(recaptchaScript)
        // https://price-table-widget.coinbase.com/embed-v1.1.js
        // https://widget.coinbase.com/embed-v1.3.js
    },
    methods: {
        async disconnect() {
            this.isLoading = true

            // await magic.connect.disconnect().catch((e) => {
            //     console.log(e);
            //     this.isLoading = false
            // });
            localStorage.removeItem("walletAccounts");

            this.$vs.notify({
                time: 2500,
                title: 'wallet disconnected',

                iconPack: 'feather',
                icon: 'icon-check',
                position: 'top-right',
                color: 'success'
            })
            this.$store.commit("auth/SET_WALLET_CONNECTION", false);
            this.$store.commit("auth/SET_WALLET_ACCOUNT",'');
            this.isLoading = false
        },
        logout() {
            this.$store.dispatch('auth/logout')
            this.$router.push('/login')
        },

        goToJobPortal() {
            return `/${this.activeUserInfo.name}/job-portal`;
            // this.$router.push(`/${this.activeUserInfo.name}/job-portal`).catch(() => {})
        },
        redirectToProfile() {
            //it need to be user name
            // if (this.activeUserInfo.isPartner) {
            //   this.$router.push(`/partner/${this.activeUserInfo.name}`)
            // } else {
            //   this.$router.push(`/profile/${this.activeUserInfo.name}`)
            // }
            if (this.activeUserInfo.isPartner) {
                return `/partner/${this.activeUserInfo.name}`;
            } else {
                return `/my-profile/${this.activeUserInfo.name}`;
            }
        },

        buyTokken() {
            this.popupMatic = true
        },
    }
}
</script>

<style>
.demo-alignment>* {
    margin-top: 0;
    margin-right: 0;
}

.profile-dropdown-menu-v2 a {
    color: inherit !important;
}

.vs-dropdown-menu {
    min-width: 120px !important;
}

.vs-dropdown--item {
    font-size: 1rem !important;
}

.dropdown-toggle::after {
    display: none;
}

.nav-link {
    color: #626262;
}

.pdd-v3 .nav-link {
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0;
}

.pdd-v3 a {
    color: #626262;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #dc123e;
}
</style>
