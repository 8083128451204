<template>
   <div class="demo-alignment profile-dropdown-menu-v2">

    <vs-dropdown>

      <a class="flex items-center" href.prevent>
          <div class="text-right leading-tight flex items-center" v-if="activeUserInfo.displayName">
            <div class="">
          <p class="font-semibold">{{ activeUserInfo.displayName }}</p>
          <small>Online</small>
            </div>
            <div class="con-img ml-2">
        <vs-avatar :src="activeUserInfo.photoURL" size="40px"/>
      </div>
          </div>
          <i class="material-icons"> expand_more </i>
      </a>

      <vs-dropdown-menu>
        <vs-dropdown-item :to="redirectToProfile()">
        <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
        <span class="ml-2">Profile </span>
        </vs-dropdown-item>
      <!--  <vs-dropdown-item :to="'/calendar'">
            <feather-icon icon="CalendarIcon" svgClasses="w-4 h-4" />
             <span class="ml-2">Calendar </span>
             </vs-dropdown-item>  -->
        <vs-dropdown-item :to="'/my-listings'">
        <feather-icon icon="MenuIcon" svgClasses="w-4 h-4" /> 
       <span class="ml-2">My listing </span>
        </vs-dropdown-item>
        <vs-dropdown-item v-if="this.activeUserInfo.isPartner === true" :to="goToJobPortal">
        <feather-icon icon="BriefcaseIcon" svgClasses="w-4 h-4" /> 
        <span class="ml-2">Job listing </span>
        </vs-dropdown-item>
        <vs-dropdown-item :to="'/user/settings'" >
        <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" /> 
       <span class="ml-2">Settings </span>
        </vs-dropdown-item>
        <vs-dropdown-item @click="logout">
        <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" /> 
       <span class="ml-2">Logout </span>
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
    
</template>

<script>
export default {
  computed: {
    activeUserInfo () {
      return this.$store.state.auth.userData
    }
  }, 
  methods: { 
    logout () {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
    goToJobPortal () {
      return `/${this.activeUserInfo.name}/job-portal`;
      // this.$router.push(`/${this.activeUserInfo.name}/job-portal`).catch(() => {})
    },
    redirectToProfile () {
      //it need to be user name
      // if (this.activeUserInfo.isPartner) {
      //   this.$router.push(`/partner/${this.activeUserInfo.name}`)
      // } else {
      //   this.$router.push(`/profile/${this.activeUserInfo.name}`)
      // }
      if (this.activeUserInfo.isPartner) {
        return `/partner/${this.activeUserInfo.name}`;
      } else {
        return `/profile/${this.activeUserInfo.name}`;
      }
    }
  }
}
</script>


<style>
.demo-alignment > * {
    margin-top:0;
    margin-right:0;
}
.profile-dropdown-menu-v2 a{
    color: inherit !important;
}
.vs-dropdown-menu{
    min-width: 120px !important;
}
.vs-dropdown--item{
    font-size: 1rem !important;
}
</style>