<template>
  <!-- <div v-if="shown" class="pwa-alert">
    <span class="pwa-info-text">Install MyPals app now.</span>
    <vs-button @click="installPWA" class="pwa-btn ml-2">Install</vs-button>
    <vs-button @click='dismissPrompt' class="ml-2 pwa-btn-border">No, thanks</vs-button>
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      shown: false,
      installEvent: null
    }
  },

  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installEvent = e;
      this.shown = true;
    });
  },

  mounted() {
    setTimeout(() => {
      this.shown = true;
    }, 5000);
  },

  methods: {
    dismissPrompt() {
      this.shown = false;
    },

    installPWA() {
      if (this.installEvent) {
        this.installEvent.prompt();
        this.installEvent.userChoice.then((choice) => {
          this.dismissPrompt(); // Hide the prompt once the user clicks
          if (choice.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
        });
      } else {
        console.log('No install event available');
      }
    }
  }
}
</script>


<style scoped>
.pwa-alert {
  background-color: #000000;
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem; /* Smaller font size for better fit */
}

.pwa-info-text {
  font-size: 1rem;
  color: #ffffff;
  flex: 1;
  margin-right: 1rem;
}

.pwa-btn {
  background-color: #28a745;
  color: rgb(255, 255, 255);
  border: none;
  padding: 0.375rem 0.75rem; /* Reduced padding for smaller button size */
  border-radius: 0.25rem;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: background-color 0.15s ease-in-out;
  font-size: 0.875rem; /* Smaller font size for better fit */
}

.pwa-btn:hover {
  background-color: #218838;
}

.pwa-btn-border {
  background-color: white;
  color: #dc3545;
  border: 1px solid #dc3545;
  padding: 0.375rem 0.75rem; /* Reduced padding for smaller button size */
  border-radius: 0.25rem;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: background-color 0.15s ease-in-out;
  font-size: 0.875rem; /* Smaller font size for better fit */
}

.pwa-btn-border:hover {
  background-color: #dc3545;
  color: white;
}
</style>


