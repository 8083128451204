<template functional>
    <footer class="the-footer flex-wrap justify-between" :class="classes">
        <p>
          <span>COPYRIGHT &copy;</span>
          <span>{{ new Date().getFullYear() }} </span>
          <a href="https://Coursepal.app" target="_blank" rel="nofollow">Coursepal</a>
          <span class="hidden sm:inline-block">, All Rights Reserved</span>
        </p>
      <!--  <span class="md:flex hidden items-center">
            <span>Powered by <a href="#" target="_blank" rel="nofollow">BRIKS Technology Inc.</a></span>
        </span>  -->
    </footer>
</template>

<script>
export default {
  name: 'the-footer',
  props: {
    classes: {
      type: String
    }
  }
}
</script>
