export default [
  {
    header: 'Main Menu',
    icon: 'FileIcon',
    i18n: 'MainMenu',
    items: [
      {
        url: '/connect',
        slug: 'connect',
        name: 'Connect',
        icon: 'GridIcon',
        i18n: 'Connect'
      },



      // {
      //   url: '/course-lists',
      //   slug: 'Courses',
      //   name: 'Courses',
      //   icon: 'BookOpenIcon'
      // },
      
      // {
      //   url: '/coursefeeds',
      //   slug: 'Coursefeeds',
      //   name: 'Groups',
      //   icon: 'UsersIcon'
      // },

      // {
      //   url: '/marketplace',
      //   slug: 'marketplace',
      //   name: 'Marketplace',
      //   icon: 'ShoppingCartIcon'
      // },
  
      //  {
      //   url: '/sessions',
      //   slug: 'sessions',
      //   name: 'Sessions',
      //   icon: 'CalendarIcon'
      // },

      // {
      //   url: '/events',
      //   slug: 'events',
      //   name: 'Events',
      //   icon: 'CalendarIcon'
      // },
      // {
      //   url: '/job-portal',
      //   slug: 'job-portal',
      //   name: 'Job Portal',
      //   icon: 'BriefcaseIcon',
      //   i18n: 'JobPortal'
      // },
      // {
      //   url: '/chat',
      //   slug: 'chat',
      //   name: 'Chat Room',
      //   icon: 'MessageCircleIcon'
      // },
    ]
  }
]

