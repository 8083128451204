<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown
    vs-custom-content
    vs-collapse
    vs-trigger-click
    @click="onReadNotification"
    class="cursor-pointer"
  >
    <feather-icon
      icon="BellIcon"
      class="cursor-pointer mt-1 sm:mr-6 mr-0 md-mr-2"
      :badge="unreadNotifications.length"
    />

    <vs-dropdown-menu
      class="notification-dropdown dropdown-custom vx-navbar-dropdown"
    >
      <div class="notification-top text-center p-3 bg-primary text-white">
        <h4 class="text-white">Total {{ notifications.length }}</h4>
        <p class="opacity-75">Notifications</p>
      </div>

      <component
        :is="scrollbarTag"
        ref="mainSidebarPs"
        class="scroll-area--nofications-dropdown p-0 mb-10"
        :settings="settings"
        :key="$vs.rtl"
        @ps-y-reach-end="onReachBottom"
        @ps-scroll-up="lockFetch = false"
      >
        <ul class="bordered-items">
          <li
            v-for="(ntf, index) in notifications"
            :key="index"
            class="flex justify-between px-4 py-4 cursor-pointer"
          >
            <div class="flex items-start" @click="viewCurrentPost(ntf)">
              <feather-icon
                icon="MessageSquareIcon"
                :svgClasses="[`text-primary`, 'stroke-current mr-1 h-6 w-6']"
              ></feather-icon>
              <div class="mx-2">
                <!-- <span class="font-medium block notification-title" :class="[`text-primary`]">{{ ntf.content }}</span> -->
                <small>{{ ntf.content }}</small>
              </div>
            </div>
            <small class="mt-1 whitespace-no-wrap">{{
              ntf.timestamp | elapsedTime
            }}</small>
          </li>
        </ul>
      </component>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      lockFetch: true
    }
  },
  computed: {
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    notifications () {
      return this.$store.state.notification.notifications
    },
    unreadNotifications () {
      return this.$store.state.notification.notifications.filter(
        (notification) => notification.seen === false
      )
    }
  },
  created () {
    this.$store.dispatch('notification/setupFirebaseMessaging')
    this.$store.dispatch('notification/fetchNotifications')
  },
  methods: {
    onReadNotification () {
      this.$store.dispatch('notification/markSeen')
    },
    viewCurrentPost (ntf) {
      if (ntf.type !== 'FOLLOW') {
        if (ntf.refTable === 'Notification') this.$router.push(`/post?postId=${ntf.ref.id}`).catch(() => {})
        else if (ntf.refTable === 'NotificationComment') this.$router.push(`/post?postId=${ntf.ref.postID}`).catch(() => {})
        else if (ntf.refTable === 'NotificationSubComment') this.$router.push(`/post?postId=${ntf.ref.comment.postID}`).catch(() => {})
      }
    },
    onReachBottom () {
      if (!this.lockFetch) {
        this.$store.commit('notification/INCREASE_NEXT_PAGE')
        this.$store.dispatch('notification/fetchNotifications')
      }
      this.lockFetch = true
    }
  }
}
</script>

