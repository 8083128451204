<template>
<div class="relative">

    <div class="vx-navbar-wrapper vt-step-1 vt-step-2 vt-step-3 vt-step-4 vtm-step-2 vtm-step-3 vtm-step-4" :class="classObj">

        <paw-alert @close-pwa="closePwa()" :showAlert="this.visible" />
        <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">
            <!-- SM - OPEN SIDEBAR BUTTON -->
            <feather-icon class="sm:inline-flex hidden md:hidden xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" />
     
            <!--  <vs-button class='ml-2' color="primary" type='border' icon-pack="feather" radius icon="icon-credit-card" size="" ></vs-button> -->
            <!-- <img width="26px" title="" class="cursor-pointer ml-3" @click='buyTokken()' src="https://coursepalconnectbucket165351-production.s3.ap-southeast-2.amazonaws.com/public/polygon-matic-logo.svg" /> -->
            <pwa />

            <!-- <list-my-services /> -->
            <vs-spacer class="hidden-sm" />

            <!-- <educator-session-dropdown v-if="!isPartner"/> -->
            <!-- <search-bar /> -->
    
            <notification-drop-down />
            <!-- <profile-drop-down /> -->
            <!-- <ProfileDropDownV2 /> -->
            <ProfileDropDownV3 />
        </vs-navbar>
    </div>


</div>
</template>

<script>

// import SearchBar from './components/SearchBar.vue'
import NotificationDropDown from './components/NotificationDropDown.vue'
import ProfileDropDown from './components/ProfileDropDown.vue'



import ProfileDropDownV2 from './components/ProfileDropDownV2.vue'
import ProfileDropDownV3 from './components/ProfileDropDownV3.vue'

import pwa from './components/pwa.vue'
import PawAlert from './components/PawAlert.vue'

export default {
    name: 'the-navbar-vertical',
    data() {
        return {
            visible: true,
          
        }
    },
    props: {
        navbarColor: {
            type: String,
            default: '#fff'
        }
    },
    components: {
     
        // SearchBar,
        NotificationDropDown,
        ProfileDropDown,
      

      
        ProfileDropDownV2,
        ProfileDropDownV3,
 
        pwa,
        PawAlert
    },
    computed: {
        navbarColorLocal() {
            return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor
        },
        verticalNavMenuWidth() {
            return this.$store.state.verticalNavMenuWidth
        },
        textColor() {
            return {
                'text-white': (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark')
            }
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        isPartner() {
            return this.$store.state.auth.userData.isPartner
        },
        // NAVBAR STYLE
        classObj() {
            if (this.verticalNavMenuWidth === 'default') return 'navbar-default'
            else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced'
            else if (this.verticalNavMenuWidth) return 'navbar-full'
        }
    },
    methods: {
        showSidebar() {
            this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
        },
        closePwa() {
            this.visible = false
        },
        buyTokken() {
            this.popupMatic = true
        },
        showWallet() {
            this.wallet = true
        },
      

    }
}
</script>

<style>
.polygon {
    width: 400px;
    margin: auto;
}

.image-size {
    width: 100px;
    margin: auto;
}
</style>
